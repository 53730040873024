import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>PAGE NOT FOUND</h1>
    <p>Go back before it is too late!</p>
  </Layout>
)

export default NotFoundPage
